'use strict'

import Polyfills from './Polyfills.js'
import Bartender from '@fokke-/bartender.js'
import boringmenu from '@teppokoivula/boringmenu'
import Glightbox from 'glightbox'

import Swal from 'sweetalert2'

import Swiper, { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'

import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import calendarFILocale from '@fullcalendar/core/locales/fi'

import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

/**
 * Site class contains general purpose site-specific features.
 *
 * @version 1.1.0
 */
export default class Site {

	/**
	 * Class constructor
	 *
	 * @param {Object} options Options for the class.
	 */
	constructor (options = {}) {
		this.options = {}

		// Init polyfills
		const polyfills = new Polyfills()
		polyfills.init()

		// Init off-canvas after mobile menu is ready
		document.addEventListener('boringmenu-init-done', () => {
			window.bartender = new Bartender({
				debug: false,
				trapFocus: true,
				overlay: true,
			})
		})

		// Init mobile menu
		const mobileMenu = document.getElementById('mobile-menu')
		new boringmenu({
			selectors: {
				menu: '.menu-mobile__list--level-1',
			},
			classes: {
				item: 'menu-mobile__item',
				itemActive: 'menu-mobile__item--current',
				itemParent: 'menu-mobile__item--parent',
				toggle: 'menu-mobile__toggle',
				toggleTextContainer: 'sr-only',
			},
			labels: {
				'menu.open': mobileMenu ? mobileMenu.getAttribute('data-labels-open') : 'open',
				'menu.close': mobileMenu ? mobileMenu.getAttribute('data-labels-close') : 'close',
			},
			icons: {
				'menu.open': 'icon-open',
				'menu.close': 'icon-close',
			},
		})

		// Initialize
		this.init(options)
	}

	/**
	 * Init the class by calling applicable init methods
	 *
	 * @param {Object} options Options for the class.
	 * @return {Object}
	 */
	init (options = {}) {

		// Merge user options to the defaults
		this.options = {
			responsiveTables: {
				selector: 'main table:not(.no-wrap)',
			},
			imageLinks: {
				parentSelector: 'main',
			},
			...options,
		}

		// Call individual init methods
		this.initResponsiveTables()
		this.initSortableTables()
		this.initSkipLinks()
		this.initImageLinks()
		this.initCopyLink()
		this.initAccordions()
		this.initCalendars()
		this.initCalendarToTemplate()
		this.initCalendarFromTemplate()
		this.initHamburgers()
		this.initSwiper()
		this.initModal()
		this.initSteps()
		this.initGetOverHere()
		this.initLimitText()
		this.initAutoRows()
		this.initPaymentButton()
		this.initPriceList()
		this.initTableSelect()
		this.initSubmitOnSelect()
		this.initProductsConfirm()
		this.initFilterableTables()
		this.initExpandableCards()

		// Dispatch custom event when init is done
		document.dispatchEvent(
			new CustomEvent('site-init-done', {
				bubbles: true,
				cancelable: true,
			})
		)

		return this
	}

	/**
	 * Initialize responsive tables
	 *
	 * Finds content tables and wraps them with div.table-wrapper.
	 */
	initResponsiveTables () {
		document.querySelectorAll(this.options.responsiveTables.selector).forEach(table => {
			if (!table.closest('.table-wrapper')) {
				const tableWrapper = document.createElement('div')
				tableWrapper.classList.add('table-wrapper')
				tableWrapper.classList.add('overflow-x-auto')
				table.parentNode.insertBefore(tableWrapper, table)
				tableWrapper.appendChild(table)
			}
		})
	}

	/**
	 * initialize sortable tables
	 *
	 * https://stackoverflow.com/questions/14267781/sorting-html-table-with-javascript
	 *
	 * @param {Object} context
	 */
	initSortableTables (context = document) {
		const getCellValue = (tr, idx) => tr.children[idx].textContent
		const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
			v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
		)(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx))
		context.querySelectorAll('.js-sortable-table').forEach(table => {
			table.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
				const table = th.closest('table')
				table.querySelectorAll('.js-sortable-table__sort').forEach(th => {
					th.classList.remove('js-sortable-table__sort')
					th.classList.remove('js-sortable-table__sort--asc')
					th.classList.remove('js-sortable-table__sort--desc')
				})
				th.classList.add('js-sortable-table__sort')
				th.classList.add('js-sortable-table__sort--' + (this.asc ? 'asc' : 'desc'))
				const tbody = table.querySelector('tbody')
				Array.from((tbody || table).querySelectorAll('tr'))
					.sort(comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc))
					.forEach(tr => (tbody || table).appendChild(tr))
				const download = table.parentNode.classList.contains('table-wrapper') ? table.parentNode.nextElementSibling : table.nextElementSibling
				if (download && download.hasAttribute('download') && download.getAttribute('href').match('&sort=')) {
					let sortKey = th.getAttribute('data-column-key')
					let sortModifier = this.asc ? '' : '-'
					if (th.hasAttribute('data-column-invert-sort')) {
						sortModifier = sortModifier == '' ? '-' : ''
					}
					download.setAttribute('href', download.getAttribute('href').replace(
						new RegExp('&sort=.*'),
						'&sort=' + sortModifier + sortKey
					))
				}
			})))
		})
	}

	/**
	 * Initialize skip links
	 *
	 * Finds skip links and enhances their behaviour for various screen readers and mobile devices.
	 */
	initSkipLinks () {
		const skipLinks = document.querySelectorAll('.skip-link:not([data-skip-link])')
		if (skipLinks.length) {
			const skipToBlur = event => {
				if (event.target.getAttribute('data-tabindex')) {
					event.target.removeAttribute('tabindex')
					event.target.removeAttribute('data-tabindex')
				}
			}
			skipLinks.forEach(skipLink => {
				skipLink.setAttribute('data-skip-link', true)
				skipLink.addEventListener('click', event => {
					const skipTo = document.getElementById(event.target.href.split('#')[1])
					if (skipTo && skipTo.getAttribute('tabindex') != '-1') {
						event.preventDefault()
						skipTo.setAttribute('tabindex', '-1')
						skipTo.setAttribute('data-tabindex', true)
						skipTo.addEventListener('blur', skipToBlur)
						skipTo.addEventListener('focusout', skipToBlur)
						skipTo.focus()
					}
				})
			})
		}
	}

	/**
	 * Initialize image links
	 */
	initImageLinks () {

		// Parent of image links
		let parentNode = document.querySelector(this.options.imageLinks.parentSelector)
		if (!parentNode) return

		// Add glightbox class to image links
		parentNode.querySelectorAll('a[href$=".jpg"], a[href$=".jpeg"], a[href$=".png"], a[href$=".gif"]').forEach(link => {
			if (!link.classList.contains('glightbox')) {
				if (!link.getAttribute('data-title') && !link.getAttribute('data-glightbox')) {
					let figcaption = link.parentNode.querySelector('figcaption')
					if (figcaption) {
						let caption = figcaption ? figcaption.textContent : ''
						link.setAttribute('data-title', caption)
					}
				}
				link.classList.add('glightbox')
			}
		})

		// Initialize GLightbox
		if (parentNode.querySelector('.glightbox')) {
			window.glightbox = Glightbox()
		}
	}

	/**
	 * Init copy to clipboard link(s)
	 */
	initCopyLink () {
		document.querySelectorAll('.js-copy-link').forEach(link => {
			link.addEventListener('click', event => {
				event.preventDefault()
				try {
					navigator.clipboard.writeText(link.getAttribute('href'))
				} catch (error) {
					console.error('Unable to copy to clipboard', error)
				}
				Swal.fire({
					title: link.getAttribute('data-title'),
					html: link.getAttribute('data-html').replace('{link}', link.getAttribute('href')),
					customClass: {
						confirmButton: 'swal2-confirm--success',
					},
					confirmButtonColor: '#E0E6C2',
				})
			})
		})
	}

	/**
	 * Initialize accordions
	 *
	 * @param {Object} context
	 */
	initAccordions (context = document) {

		// Find accordion headers
		const accordionHeaders = context.querySelectorAll('[data-accordion-header]')
		if (!accordionHeaders.length) return

		// Add expand event to accordion buttons
		Array.prototype.forEach.call(accordionHeaders, accordionHeader => {
			let target = document.getElementById(accordionHeader.getAttribute('aria-controls'))
			if (target) {
				accordionHeader.addEventListener('click', () => {
					let expanded = accordionHeader.getAttribute('aria-expanded') == 'true' || false
					accordionHeader.setAttribute('aria-expanded', !expanded)
					target.hidden = expanded
					if (expanded) {
						target.classList.add('js-hide')
					} else {
						target.classList.remove('js-hide')
					}
					if (!target.hidden) {
						if (accordionHeader.hasAttribute('data-fetch-on-open')) {
							target.classList.add('is-ajax-loading')
							fetch(accordionHeader.getAttribute('data-fetch-on-open'), {
								headers: {
									'X-Requested-With': 'XMLHttpRequest',
								},
							})
								.then(response => response.text())
								.then(data => {
									target.innerHTML = data
									target.classList.remove('is-ajax-loading')
									accordionHeader.removeAttribute('data-fetch-on-open')
									this.initAccordionCalendars(target)
									this.initAccordions(target)
									this.initSortableTables(target)
									this.initFilterableTables(target)
									this.initExpandableCards(target)
								})
						} else {
							this.initAccordionCalendars(target)
						}
					}
				})
			}
		})

		// Automatically open accordion sections with data-accordion-auto-open attribute
		let accordionHeaderOpen = null
		document.querySelectorAll('[data-accordion-auto-open]').forEach(accordionHeader => {
			accordionHeader.dispatchEvent(new Event('click'))
			accordionHeaderOpen = accordionHeader
		})
		if (accordionHeaderOpen) {
			this.scrollTo(accordionHeaderOpen)
			const accordionTarget = document.getElementById(accordionHeaderOpen.getAttribute('aria-controls'))
			if (accordionTarget) {
				if (!accordionTarget.hasAttribute('tabindex')) {
					accordionTarget.setAttribute('tabindex', '-1')
				}
				accordionTarget.focus()
			}
		}
	}

	/**
	 * Initialize calendars within opened or AJAX loaded accordion content
	 *
	 * @param {Object} target
	 */
	initAccordionCalendars (target) {
		target.querySelectorAll('.js-accordion-calendar').forEach(calendar => {
			const calendarAccordionSection = calendar.closest('.js-accordion-section')
			if (calendarAccordionSection && calendarAccordionSection.id == target.id) {
				calendar.classList.remove('.js-accordion-calendar')
				calendar.classList.add('js-calendar')
				this.initCalendars()
			}
		})
	}

	/**
	 * Initialize calendars
	 */
	initCalendars () {

		// Find calendars
		const calendars = document.querySelectorAll('.js-calendar:not(.js-calendar-init-done)')
		if (!calendars.length) return

		// Render calendars
		calendars.forEach(calendar => {

			const events = JSON.parse(calendar.getAttribute('data-events'))
			if (!events.length && !calendar.getAttribute('data-events-not-required')) return

			let minHour = 8
			let maxHour = 17
			events.forEach(event => {
				if (event.hour_min < minHour) minHour = event.hour_min
				if (event.hour_max > maxHour) maxHour = event.hour_max
			})

			const isEditable = Boolean(calendar.getAttribute('data-calendar-is-editable'))

			const fullCalendar = new Calendar(calendar, {
				plugins: isEditable ? [
					dayGridPlugin,
					timeGridPlugin,
					listPlugin,
					interactionPlugin,
				] : [
					dayGridPlugin,
					timeGridPlugin,
					listPlugin,
				],
				initialView: document.body.clientWidth < 1024 ? 'timeGridDay' : 'timeGridWeek',
				initialDate: calendar.getAttribute('data-initial-date'),
				headerToolbar: {
					left: 'title',
					center: 'prev,next today',
					right: 'timeGridWeek,timeGridDay listWeek',
				},
				allDaySlot: false,
				slotMinTime: (minHour < 10 ? '0' + minHour : minHour) + ':00:00',
				slotMaxTime: (maxHour < 10 ? '0' + maxHour : maxHour) + ':00:00',
				height: 'auto',
				editable: isEditable,
				selectable: isEditable,
				locale: calendarFILocale,
				events: events,
				eventDidMount: info => {
					if (!info.event.extendedProps.description || !info.event.extendedProps.description.length) return
					tippy(info.el, {
						content: info.event.extendedProps.description,
					})
				},
				select: info => {
					if (!isEditable) return
					this.editCalendarEntry(info, calendar, fullCalendar)
				},
				eventClick: info => {
					if (!isEditable) return
					this.editCalendarEntry(info, calendar, fullCalendar)
				},
				eventDrop: info => {
					if (!isEditable) return
					if (!confirm(calendar.getAttribute('data-calendar-move-event-confirm-text'))) {
						info.revert()
					}
					this.saveCalendar(calendar, fullCalendar)
				},
				eventResize: info => {
					if (!isEditable) return
					if (!confirm(calendar.getAttribute('data-calendar-edit-event-confirm-text'))) {
						info.revert()
					}
					this.saveCalendar(calendar, fullCalendar)
				},
			})
			fullCalendar.render()
			calendar._fullCalendar = fullCalendar
			calendar.classList.add('js-calendar-init-done')
		})
	}

	/**
	 * Edit calendar entry
	 *
	 * @param {Object} info
	 * @param {Object} calendar
	 * @param {Object} fullCalendar
	 */
	editCalendarEntry (info, calendar, fullCalendar) {
		let typeOptions = '<option value=""></option>'
		JSON.parse(calendar.getAttribute('data-event-types')).forEach(type => {
			const typeSelected = info.event && info.event.extendedProps.type == type.id ? ' selected' : ''
			typeOptions += '<option value="' + type.id + '" data-color-code="' + (typeof type.color_code === 'undefined' ? '' : type.color_code) + '"' + typeSelected + '>' + type.title + '</option>'
		})
		const summaryValue = info.event && info.event.extendedProps.description ? ' value="' + info.event.extendedProps.description + '"' : ''
		Swal.fire({
			title: calendar.getAttribute('data-calendar-new-event-title'),
			html:
			'<label class="block mt-single">' + calendar.getAttribute('data-calendar-new-event-label-summary') + ' <input id="js-calendar-new-event-summary" class="swal2-input swal2-input--custom"' + summaryValue + ' required></label>' +
			'<label class="block mt-single">' + calendar.getAttribute('data-calendar-new-event-label-type') + ' <select id="js-calendar-new-event-type" class="swal2-input swal2-input--custom swal2-select swal2-select--custom" required>' + typeOptions + '</select></input>' +
			(info.event ? '<label class="block mt-single"><input type="checkbox" id="js-calendar-delete-event" value="1"> ' + calendar.getAttribute('data-calendar-delete-event-label') + '</label>': ''),
			showCancelButton: true,
			confirmButtonText: calendar.getAttribute('data-calendar-new-event-confirm-button-text'),
			customClass: {
				container: 'swal2-reverse',
				confirmButton: 'swal2-confirm--success',
			},
			confirmButtonColor: '#E0E6C2',
			cancelButtonColor: '#FCE5EF',
			showLoaderOnConfirm: true,
			didOpen: () => {
				const summaryInput = document.getElementById('js-calendar-new-event-summary')
				const summaryInputValue = summaryInput.value
				summaryInput.focus()
				summaryInput.value = ''
				summaryInput.value = summaryInputValue
			},
			preConfirm: () => {
				if (info.event && document.getElementById('js-calendar-delete-event') && document.getElementById('js-calendar-delete-event').checked) {
					info.event.remove()
				} else {
					const summary = document.getElementById('js-calendar-new-event-summary').value
					if (!summary) {
						document.getElementById('js-calendar-new-event-summary').focus()
						return false
					}
					const type = document.getElementById('js-calendar-new-event-type').value
					if (!type) {
						document.getElementById('js-calendar-new-event-type').focus()
						return false
					}
					const color_code = document.getElementById('js-calendar-new-event-type').options[document.getElementById('js-calendar-new-event-type').selectedIndex].getAttribute('data-color-code')
					if (summary) {
						if (info.event) {
							calendar._fullCalendar.batchRendering(() => {
								info.event.setProp('title', document.getElementById('js-calendar-new-event-type').options[document.getElementById('js-calendar-new-event-type').selectedIndex].innerText)
								info.event.setProp('color', color_code)
								info.event.setExtendedProp('description', summary)
								info.event.setExtendedProp('type', type)
							})
						} else {
							fullCalendar.addEvent({
								title: document.getElementById('js-calendar-new-event-type').options[document.getElementById('js-calendar-new-event-type').selectedIndex].innerText,
								description: summary,
								start: info.startStr,
								end: info.endStr,
								allDay: info.allDay,
								color: color_code,
								extendedProps: {
									type: type,
								},
							})
						}
					}
				}
				this.saveCalendar(calendar, fullCalendar)
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: info.event
						? (
							document.getElementById('js-calendar-delete-event') && document.getElementById('js-calendar-delete-event').checked
								? calendar.getAttribute('data-calendar-delete-event-confirmed-title')
								: calendar.getAttribute('data-calendar-edit-event-confirmed-title')
						)
						: calendar.getAttribute('data-calendar-new-event-confirmed-title'),
					confirmButtonColor: '#E0E6C2',
				})
			}
		})
		fullCalendar.unselect()
		document.querySelectorAll('[data-tippy-root]').forEach(tippyRoot => {
			tippyRoot.remove()
		})
	}

	/**
	 * Save calendar
	 *
	 * @param {Object} calendar
	 */
	saveCalendar (calendar) {
		const events = []
		calendar._fullCalendar.getEvents().forEach(event => {
			events.push({
				start_time: event.start,
				end_time: event.end,
				type: event.extendedProps.type,
				summary: event.extendedProps.description,
			})
		})
		const formData = new FormData()
		formData.set('item', calendar.getAttribute('data-calendar-event-id'))
		formData.set('field', 'timetable')
		formData.set('value', JSON.stringify(events))
		fetch(calendar.getAttribute('data-calendar-save-url'), {
			method: 'POST',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
			},
			body: formData,
		})
			.then(response => response.json())
			.then(data => {
				// batch every modification into one re-render
				calendar._fullCalendar.batchRendering(() => {
					calendar._fullCalendar.getEvents().forEach(event => event.remove())
					data.forEach(event => calendar._fullCalendar.addEvent(event))
				})
				document.querySelectorAll('[data-tippy-root]').forEach(tippyRoot => {
					tippyRoot.remove()
				})
			})
	}

	/**
	 * Initialize calendar to template feature
	 */
	initCalendarToTemplate () {
		document.addEventListener('click', event => {
			const button = event.target.closest('.js-calendar-to-template')
			if (!button) return
			event.preventDefault()
			if (button.disabled) return
			button.classList.add('button--ajax-loading')
			button.disabled = true
			const formData = new FormData()
			formData.set('event', button.getAttribute('data-event'))
			fetch(button.getAttribute('data-url'), {
				method: 'POST',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
				},
				body: formData,
			})
				.then(response => {
					if (response.ok) {
						return response.text()
					} else {
						throw new Error()
					}
				})
				.then(data => {
					Swal.fire({
						title: data.match('new')
							? button.getAttribute('data-template-create-done-title')
							: button.getAttribute('data-template-update-done-title'),
						confirmButtonColor: '#E0E6C2',
					})
					button.querySelector('.js-label').innerText = button.getAttribute('data-label-update')
					button.classList.remove('button--ajax-loading')
					button.disabled = false
				})
				.catch(error => {
					Swal.fire({
						html: '<div class="swal2-html-inner">'
							+ '<p>' + button.getAttribute('data-error-message') + '</p>'
							+ '</div>',
						confirmButtonColor: '#FCE5EF',
					})
					button.classList.remove('button--ajax-loading')
					button.disabled = false
				})
		})
	}

	/**
	 * Initialize calendar from template feature
	 */
	initCalendarFromTemplate () {
		document.addEventListener('click', event => {
			const button = event.target.closest('.js-calendar-from-template')
			if (!button) return
			event.preventDefault()
			let templateOptions = '<option value=""></option>'
			JSON.parse(button.getAttribute('data-templates')).forEach(template => {
				templateOptions += '<option value="' + template.id + '">' + template.title + '</option>'
			})
			Swal.fire({
				title: button.getAttribute('data-update-modal-title'),
				html:
				'<label class="block mt-single">' + button.getAttribute('data-label-template-select') + ' <select id="js-calendar-template-select" class="swal2-input swal2-input--custom swal2-select swal2-select--custom" required>' + templateOptions + '</select></input>',
				showCancelButton: true,
				confirmButtonText: button.getAttribute('data-confirm-button-text'),
				customClass: {
					container: 'swal2-reverse',
					confirmButton: 'swal2-confirm--success',
				},
				confirmButtonColor: '#E0E6C2',
				cancelButtonColor: '#FCE5EF',
				showLoaderOnConfirm: true,
				didOpen: () => {
					const templateSelectInput = document.getElementById('js-calendar-template-select')
					templateSelectInput.focus()
				},
				preConfirm: () => {
					const template = document.getElementById('js-calendar-template-select').value
					if (!template) {
						document.getElementById('js-calendar-template-select').focus()
						return false
					}
					const calendar = button.parentElement.previousElementSibling
					this.updateCalendar(calendar, button, template)
				},
				allowOutsideClick: () => !Swal.isLoading(),
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire({
						title: button.getAttribute('data-update-done-title'),
						confirmButtonColor: '#E0E6C2',
					})
				}
			})
		})
	}

	/**
	 * Update calendar from template
	 *
	 * @param {Object} calendar
	 * @param {Object} button
	 * @param {String} template
	 */
	updateCalendar (calendar, button, template) {
		const formData = new FormData()
		formData.set('item', button.getAttribute('data-event'))
		formData.set('field', 'timetable')
		formData.set('from', template)
		fetch(button.getAttribute('data-url'), {
			method: 'POST',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
			},
			body: formData,
		})
			.then(response => response.json())
			.then(data => {
				// batch every modification into one re-render
				calendar._fullCalendar.batchRendering(() => {
					calendar._fullCalendar.getEvents().forEach(event => event.remove())
					data.forEach(event => calendar._fullCalendar.addEvent(event))
				})
				document.querySelectorAll('[data-tippy-root]').forEach(tippyRoot => {
					tippyRoot.remove()
				})
			})
	}

	/**
	 * Initialize Hamburgers
	 */
	initHamburgers () {

		const bartenderMainWrap = document.querySelector('.bartender-main')
		const hamburger = document.querySelector('.hamburger')
		if (!bartenderMainWrap || !hamburger) return

		bartenderMainWrap.addEventListener('bartender-open', e => {
			hamburger.classList.add('is-active')
		})
		bartenderMainWrap.addEventListener('bartender-close', e => {
			hamburger.classList.remove('is-active')
		})
	}

	/**
	 * Scroll to an element
	 *
	 * @param {Object} target
	 * @param {Boolean} focus Focus on target element after scrolling, defaults to false
	 * @param {String} position 'top', 'center' or 'bottom', defaults to 'top'
	 */
	scrollTo (target, focus = false, position = 'top') {

		if (target === null) return

		// take adminbar into account
		let offset = 0
		if (document.getElementById('adminbar')) {
			offset += document.getElementById('adminbar').offsetHeight + 16
		}

		// define desired scroll top value depending on provided position string
		let top = parseInt(target.getBoundingClientRect().top + window.pageYOffset)
		if (position == 'top') {
			top -= offset
		} else if (position == 'center') {
			top -= parseInt((window.innerHeight - target.offsetHeight) / 2)
		} else if (position == 'bottom') {
			top -= parseInt(window.innerHeight - target.offsetHeight - 16)
		}

		// scroll to element
		window.scroll({
			top: top,
			behavior: 'smooth',
		})

		// focus as well?
		if (focus) {
			target.focus({
				preventScroll: true,
			})
		}
	}

	/**
	 * Initialize Swiper carousel
	 */
	initSwiper () {
		const slideCount = document.querySelectorAll('.swiper-slide').length
		const swiper = new Swiper('.swiper', {
			modules: [
				Pagination,
			],

			// Optional parameters
			direction: 'horizontal',
			loop: false,
			slidesPerView: 1,
			spaceBetween: 0,
			watchOverflow: true,
			simulateTouch: true,

			// If we need pagination
			pagination: {
				el: '.swiper-pagination',
			},

			breakpoints: {
				600: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 4,
				},
				1280: {
					slidesPerView: 5,
				},
			},
		})
	}

	/**
	 * Initialize modals
	 */
	initModal () {
		document.addEventListener('click', event => {
			const toggle = event.target.closest('.js-modal-toggle')
			if (!toggle) return
			event.preventDefault()
			if (toggle.classList.contains('js-modal-toggle--fetch')) {
				if (toggle.getAttribute('data-modal-html')) {
					this.showModal(toggle, toggle.getAttribute('data-modal-width') || '95%')
				} else if (toggle.getAttribute('data-modal-iframe')) {
					toggle.setAttribute('data-modal-html', '<iframe class="swal2-iframe" src="' + toggle.getAttribute('data-modal-iframe') + '"></iframe>')
					toggle.classList.remove('is-ajax-loading')
					toggle.removeAttribute('disabled')
					this.showModal(toggle, toggle.getAttribute('data-modal-width') || '95%', '95%')
				} else {
					toggle.setAttribute('disabled', '')
					toggle.classList.add('is-ajax-loading')
					fetch(toggle.getAttribute('href'), {
						headers: {
							'X-Requested-With': 'XMLHttpRequest',
						},
					})
						.then(response => response.text())
						.then(data => {
							toggle.setAttribute('data-modal-html', data)
							toggle.classList.remove('is-ajax-loading')
							toggle.removeAttribute('disabled')
							this.showModal(toggle, toggle.getAttribute('data-modal-width') || '95%')
						})
				}
			} else {
				this.showModal(toggle, 736)
			}
		})
	}

	/**
	 * Show modal window
	 *
	 * @param {Object} toggle
	 * @param {String} modalWidth
	 * @param {String} modalHeight
	 */
	showModal (toggle, modalWidth, modalHeight) {
		Swal.fire({
			title: toggle.getAttribute('data-modal-title'),
			html: '<div class="swal2-html-inner">' + toggle.getAttribute('data-modal-html') + '</div>',
			width: modalWidth,
			heightAuto: modalHeight ? false : true,
			confirmButtonColor: '#fff',
			confirmButtonText: '<svg aria-hidden="true" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m25.333 8.547-1.88-1.88L16 14.12 8.546 6.667l-1.88 1.88L14.12 16l-7.453 7.453 1.88 1.88L16 17.88l7.453 7.453 1.88-1.88L17.88 16l7.453-7.453Z" fill="#fff"/></svg>'
				+ '<span class="sr-only">OK</span>',
			customClass: {
				confirmButton: 'swal2-confirm--information',
				actions: 'swal2-actions--minimal',
			},
			didClose: () => {
				toggle.removeAttribute('disabled')
				if (toggle.hasAttribute('data-reload-after-close')) {
					let url = window.location.href.replace(/\?pid=[0-9]+/, '')
					let urlParam = toggle.getAttribute('data-reload-after-close')
					if (url.indexOf('?' + urlParam) === -1 && url.indexOf('&' + urlParam) === -1) {
						url += (url.indexOf('?') > -1 ? '&' : '?') + urlParam
					}
					window.location.href = url
				}
			},
		})
		this.initLimitText()
		this.initCalendars()
	}

	/**
	 * Initialize steps
	 */
	initSteps () {
		document.addEventListener('click', event => {
			const step = event.target.closest('.js-step')
			if (!step || !step.getAttribute('data-href')) return
			const href = step.getAttribute('data-href')
			document.location.href = href.match('=$') ? href + '1' : href
		})
	}

	/**
	 * Init "get over here" buttons
	 */
	initGetOverHere () {

		const buttons = document.querySelectorAll('.js-get-over-here')
		if (!buttons.length) return

		buttons.forEach(button => {
			const cloneButton = button.cloneNode(true)
			cloneButton.classList.add('md:hidden')
			cloneButton.classList.add('button--fixed-to-bottom')
			document.body.appendChild(cloneButton)

			cloneButton.addEventListener('click', event => {
				event.preventDefault()
				cloneButton.classList.add('button--invisible')
				cloneButton._gettingOverThere = true
				cloneButton.hidden = true
				this.scrollTo(button, true, 'bottom')
			})

			window.addEventListener('scroll', () => {
				const rect = button.getBoundingClientRect()
				if (rect.top < window.innerHeight && rect.bottom >= 0) {
					if (!cloneButton.hidden) {
						cloneButton.hidden = true
						cloneButton.classList.add('button--invisible')
					} else if (cloneButton._gettingOverThere) {
						cloneButton._gettingOverThere = false
					}
				} else if (cloneButton.hidden && !cloneButton._gettingOverThere) {
					cloneButton.classList.remove('button--invisible')
					cloneButton.hidden = false
				}
			})
		})
	}

	/**
	 * Init limit text feature, i.e. hide part of text until link is clicked
	 */
	initLimitText () {

		const texts = document.querySelectorAll('.js-limit-text:not([data-limit-text-more])')
		if (!texts.length) return

		texts.forEach(text => {
			const textLength = text.getAttribute('data-limit-text-length')
			if (text.innerText.length > textLength) {
				text.setAttribute('data-limit-text-more', text.innerHTML)
				text.setAttribute('data-limit-text-less', text.innerText.slice(0, textLength) + '… ')
				text.innerHTML = text.getAttribute('data-limit-text-less')
				const button = document.createElement('button')
				button.classList.add('a')
				button.innerText = text.getAttribute('data-limit-text-label-more')
				button.addEventListener('click', event => {
					event.preventDefault()
					if (button.hasAttribute('data-limit-text-expanded')) {
						text.innerText = text.getAttribute('data-limit-text-less')
						button.removeAttribute('data-limit-text-expanded')
						button.innerText = text.getAttribute('data-limit-text-label-more')
						text.appendChild(button)
					} else {
						text.innerHTML = text.getAttribute('data-limit-text-more')
						button.setAttribute('data-limit-text-expanded', '')
						button.innerText = text.getAttribute('data-limit-text-label-less')
						text.appendChild(button)
					}
				})
				text.appendChild(button)
			}
		})
	}

	/**
	 * Iinit auto rows feature, where input (textarea) is scaled automatically based on contained text
	 */
	initAutoRows () {

		const inputs = document.querySelectorAll('.js-auto-rows')
		if (!inputs.length) return

		inputs.forEach(input => {
			input.addEventListener('input', () => {
				input.style.height = ''
				input.style.height = input.scrollHeight + 'px'
			})
		})
	}

	/**
	 * Init payment button(s)
	 */
	initPaymentButton () {

		const buttons = document.querySelectorAll('.js-payment-button')
		if (!buttons.length) return

		buttons.forEach(button => {
			button.addEventListener('click', event => {
				event.preventDefault()
				if (button.disabled) return
				button.classList.add('button--ajax-loading')
				button.disabled = true
				fetch(button.getAttribute('data-href'), {
					method: 'GET',
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
					},
				})
					.then(response => {
						if (response.ok) {
							return response.text()
						} else {
							throw new Error()
						}
					})
					.then(data => {
						const formContainer = document.createElement('div')
						formContainer.classList.add('hidden')
						formContainer.innerHTML = data
						button.insertAdjacentElement('afterend', formContainer)
						formContainer.querySelector('form').submit()
					})
					.catch(error => {
						Swal.fire({
							html: '<div class="swal2-html-inner">'
								+ '<p>' + button.getAttribute('data-error-message') + '</p>'
								+ '</div>',
							confirmButtonColor: '#FCE5EF',
						})
						button.classList.remove('button--ajax-loading')
						button.disabled = false
					})
			})
		})
	}

	/**
	 * Init price list features
	 */
	initPriceList () {

		const priceList = document.getElementById('js-price-list')
		if (!priceList) return

		document.querySelectorAll('.js-price-list-switch').forEach(priceListSwitch => {
			priceListSwitch.addEventListener('click', () => {
				if (priceListSwitch.value == 'full') {
					priceList.classList.remove('js-price-list--discount')
					priceList.classList.add('js-price-list--full')
				} else {
					priceList.classList.remove('js-price-list--full')
					priceList.classList.add('js-price-list--discount')
				}
			})
		})
	}

	/**
	 * Init table select feature
	 */
	initTableSelect () {
		document.addEventListener('change', event => {
			const select = event.target
			if (!select.classList.contains('js-table-select')) return
			const oldValue = select.getAttribute('data-value')
			const formData = new FormData()
			formData.set('item', select.getAttribute('data-item'))
			formData.set('field', select.getAttribute('data-field'))
			formData.set('old_value', oldValue)
			const isNew = select.value == 0
			if (isNew) {
				let newValue = window.prompt(select.getAttribute('data-prompt-for-new'))
				if (!newValue) {
					select.value = oldValue
					return
				}
				formData.set('new_value', newValue)
			}
			formData.set('value', select.value)
			select.classList.add('is-ajax-loading')
			select.disabled = true
			fetch(select.getAttribute('data-url'), {
				method: 'POST',
				body: formData,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
				},
			})
				.then(response => {
					if (response.ok) {
						return response.text()
					} else {
						throw new Error()
					}
				})
				.then(data => {
					Swal.fire({
						icon: 'success',
						title: select.getAttribute('data-success-message'),
						confirmButtonColor: '#F3F5E6',
						customClass: {
							container: 'swal2-reverse',
						},
					})
					if (isNew && data.match('=')) {
						let value = data.split('=')
						const option = document.createElement('option')
						option.value = parseInt(value[0])
						option.innerText = value[1]
						let lastOption = select.options[select.options.length - 1]
						lastOption.insertAdjacentElement('beforebegin', option)
						select.setAttribute('data-value', option.value)
						select.value = option.value
						select.closest('table').querySelectorAll('select[data-field="' + select.getAttribute('data-field') + '"]').forEach(tableSelect => {
							if (tableSelect === select) return
							lastOption = tableSelect.options[tableSelect.options.length - 1]
							lastOption.insertAdjacentElement('beforebegin', option.cloneNode(true))
						})
					} else {
						let value = parseInt(data)
						select.setAttribute('data-value', value)
					}
					if (select.previousElementSibling && select.previousElementSibling.classList.contains('js-table-select-value')) {
						select.previousElementSibling.innerText = select.options[select.selectedIndex].innerText.trim()
					}
					select.classList.remove('is-ajax-loading')
					select.disabled = false
				})
				.catch(error => {
					Swal.fire({
						icon: 'error',
						title: select.getAttribute('data-error-message'),
						confirmButtonColor: '#FCE5EF',
					})
					select.classList.remove('is-ajax-loading')
					select.disabled = false
				})
		})
	}

	/**
	 * Init submit on select feature
	 */
	initSubmitOnSelect () {
		document.addEventListener('change', event => {
			const select = event.target
			if (!select.classList.contains('js-submit-on-select')) return
			if (select.options[select.selectedIndex].value) {
				select.closest('form').submit()
			}
		})
	}

	/**
	 * Init products confirm feature
	 */
	initProductsConfirm () {
		document.addEventListener('click', event => {
			const button = event.target
			if (!button.classList.contains('js-products-confirm')) return
			event.preventDefault()
			const form = button.closest('.js-products-form')
			form.querySelectorAll('.js-form-error').forEach(formError => {
				formError.remove()
			})
			const emptyOrderError = form.querySelector('.js-products-form-empty-order-error')
			if (!form.querySelector('input[name^="product_quantity_"]:checked')) {
				emptyOrderError.removeAttribute('hidden')
				emptyOrderError.focus()
				return
			} else {
				emptyOrderError.setAttribute('hidden', true)
			}
			let hasError = false
			if (!hasError) {
				form.querySelectorAll('input[required]').forEach(requiredInput => {
					if (!requiredInput.checkValidity()) {
						hasError = true
						const formError = document.createElement('div')
						formError.classList.add('js-form-error')
						formError.innerText = requiredInput.validationMessage
						const formErrorContainer = requiredInput.nextElementSibling.querySelector('.js-form-error-container') || formField.nextElementSibling
						formErrorContainer.insertAdjacentElement('afterbegin', formError)
						formError.setAttribute('tabindex', -1)
						formError.focus()
						return
					}
				})
			}
			if (hasError) return
			button.classList.add('button--ajax-loading')
			form.classList.add('js-form-in-progress')
			const formData = new FormData(form)
			form.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
				checkbox.disabled = true
			})
			fetch(form.getAttribute('action'), {
				body: formData,
				method: 'post',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
				},
			})
				.then(response => response.json())
				.then(data => {
					if (data.error) {
						Swal.fire({
							html: '<div class="swal2-html-inner">'
								+ '<p>' + data.error + '</p>'
								+ '</div>',
							confirmButtonColor: '#FCE5EF',
						})
					} else {
						Swal.fire({
							html: '<div class="swal2-html-inner">'
								+ '<p>' + data.message + '</p>'
								+ '</div>',
							customClass: {
								confirmButton: 'swal2-confirm--success',
							},
							confirmButtonColor: '#E0E6C2',
							didClose: () => {
								window.location.href = data.redirect || form.getAttribute('data-redirect')
							},
						})
					}
				})
		})
	}

	/**
	* Init the filterable tables to filter items based on text content.
	*
 	* @param {Object} context
	*/
	initFilterableTables (context = document) {
		context.querySelectorAll('[data-js-search-values]').forEach(search => {
			search.addEventListener('input', function (e) {
				const value = e.target.value.toLowerCase().trim()

				function filterElements (containerSelector, answerSelector, searchValue) {
					const elements = document.querySelectorAll(containerSelector)

					elements.forEach(element => {
						const answers = element.querySelectorAll(answerSelector)
						const matches = Array.from(answers).some(answerEl =>
							answerEl.textContent.toLowerCase().includes(searchValue)
						)
						element.style.display = matches ? '' : 'none'
					})
				}

				filterElements('tbody tr', 'td', value)
				filterElements('li', 'div', value)
			})
		})
	}

	/**
	* Init card open/close feature.
	*
 	* @param {Object} context
	*/
	initExpandableCards (context = document) {
		const expandableButtons = context.querySelectorAll('[data-js-expand-button]')

		if (!expandableButtons.length) return

		expandableButtons.forEach(button => {
			button.addEventListener('click', (e) => {
				e.target.closest('li').querySelector('[data-js-expandable]').classList.toggle('hidden')
			})
		})
	}

}
